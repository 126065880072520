<template>
<div class="app_page_container" style="background-color: #FFF;display: flex;flex-direction: column;">
  <van-nav-bar
      title="提交成功"
  />
  <div style="flex: 1;display: flex;flex-direction: column;align-items: center;justify-content: center;">
    <div style="width: 80%;height: auto;margin-top: -92px;">
      <div style="width: 80px;height: 80px;display: flex;flex-direction: column;align-items: center;justify-content: center;border-radius: 100%;background-color: #1989fa;margin: auto;">
        <van-icon name="success" color="#FFF" size="30"/>
      </div>
      <div style="margin-top: 20px;font-size: 1.2em;font-weight: bold;text-align: center">操作成功</div>
      <van-button @click="toSignUpList" style="margin-top: 28px;" type="info" block>报名列表</van-button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "SubmitOk",
  data(){
    return {

    }
  },
  methods: {
    toSignUpList(){
      this.$router.replace({
        path: "/wx/signUpList"
      })
    },
    onClickLeft(){
      this.$router.push({
        path: '/wx/home'
      })
    }
  }
}
</script>

<style scoped>

</style>