import { render, staticRenderFns } from "./SubmitOk.vue?vue&type=template&id=801f4950&scoped=true&"
import script from "./SubmitOk.vue?vue&type=script&lang=js&"
export * from "./SubmitOk.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "801f4950",
  null
  
)

export default component.exports